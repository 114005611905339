<template>
  <div class="body">
    <div class="content">
      <div class="title">
        <span>绑定域名</span>
      </div>
      <div style="margin: 20px"></div>
      <div class="list">
        <el-form label-width="130px" size="mini">
          <el-form-item label="前台网站域名" :required="true">
            <el-input size="medium" v-model="viewUrl"></el-input>
          </el-form-item>
        </el-form>
        <div style="font-size: 14px; color: #999">提示:请输入用户端展示的域名，用于DIY的网站访问</div>
      </div>
    </div>

    <el-button type="primary" style="margin-left: 120px; width: 200px" @click="submit">保存</el-button>
  </div>
</template>

<script>
import http from '@/utils/cloud'
export default {
  data() {
    return {
      viewUrl: '',
      oss: {
        region: '',
        accessKeyId: '',
        accessKeySecret: '',
        bucket: ''
      }
    }
  },
  created() {
    this.getText()
  },
  methods: {
    getText() {
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSystem'
        })
        .then(res => {
          if (res) {
            this.oss = res.oss
            this.viewUrl = res.viewUrl
          }
        })
    },
    submit() {
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'updateSystem',
          oss: this.oss,
          viewUrl: this.viewUrl
        })
        .then(res => {
          this.$message({
            message: '设置成功',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100%;
  padding: 15px;

  .content {
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-bottom: 20px;
    ::v-deep .el-input--medium {
      width: 300px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      padding-left: 12px;
      border-left: 6px solid #6775e1;
    }
  }
}
</style>
